<template>
    <list-table-layout 
        v-if="loaded"
        :header="`Invoices (${invoices.length})`"
        :records="invoices"
        :model="InvoiceModel"
        :columns="columns"
        :row-controls-width="1"
        >
        <template v-slot:rowcontrols="{record}">
            <button @click="open(record.id)"><i class="fa-solid fa-arrow-up-right-from-square"></i></button>
            <button @click="download(record.id)"><i class="fa-regular fa-download"></i></button>
        </template>
        <template v-slot:table-controls>
            <router-link to="/billing" class="button"><i class="fa-regular fa-credit-card-front"></i> Make a Payment</router-link>
        </template>
    </list-table-layout>
</template>

<script>
import { ref } from '@vue/reactivity';
import ListTableLayout from '../../components/layouts/ListTableLayout.vue'
import InvoiceModel from '../../models/InvoiceModel';
import InvoiceStatusType from '../../models/InvoiceStatusType';
import database from "@/store/database";

const columns = {
    'invoiceDate' : {
        title : "Invoice Date",
        sortable: true,
        width: 2, 
    },
    'invoiceNumber' : {
        title : "Invoice #",
        sortable: true,
        width: 3,
    },
    'clientAccount': {
        title: "Account",
        sortable: true,
        format: '{clientAccount.name} - {clientAccount.accountNumber}',
        width: 5,
    },
    'equipment.trailerNumber' : {
        title : "Equipment",
        sortable: 'serviceRequest.equipmentIssues.equipment.trailerNumber',
        multiple: 'ifcount',
        width: 2,
    },
    'total' : {
        title: "Total",
        sortable: true,
        format: 'money',
        width: 2,
    },
    'balanceDue' : {
        title: "Balance",
        format: 'money',
        width: 2,
    },
    'status' : {
        title: "Status",
        sortable: true,
        options: InvoiceStatusType,
        width: 2,
    },
}


export default {
    components: {
        ListTableLayout,
    },
    setup(){
        const promises = [];
        const loaded = ref(false);

        const invoices = database[InvoiceModel.name].list;

        Promise.all(promises).then(() => {
            loaded.value = true;
        });

        return {invoices, loaded, InvoiceModel, columns}

    },
    methods: {
        open(){
            alert("!!Opened the PDF in a new tab");
        },
        download(){
            alert("!!Downloaded the PDF")
        }
    },
}
</script>
<style lang="scss">

@import "../../assets/scss/variables.scss";

</style>